<template>
    <navbar
            position="fixed"
            :transparent="transparent"
            :color-on-scroll="colorOnScroll"
            menu-classes="ml-auto"
    >
        <template>
            <router-link
                    v-popover:popover1
                    class="navbar-brand"
                    to="/#top"
            >
                IT Link A
            </router-link>
            <el-popover
                    ref="popover1"
                    popper-class="popover"
                    placement="bottom"
                    width="200"
                    trigger="hover"
            >
                <div class="popover-body">
                    Empowering Your Business Through Innovative Software Solutions.
                </div>
            </el-popover>
        </template>
    </navbar>
</template>

<script>
    import { DropDown, Navbar, NavLink } from '@/components';
    import { Popover } from 'element-ui'
    export default {
        name: 'ITLinkAImprintNavbar',
        components: {
            DropDown,
            Navbar,
            NavLink,
            [Popover.name]: Popover
        },
        props: {
            transparent: Boolean,
            colorOnScroll: Number,
        }
    }
</script>

<style scoped>

</style>
