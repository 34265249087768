<template>
  <div
    id="headers"
    class="cd-section"
  >
    <!--     *********     HEADER 1      *********      -->
    <div class="header-1">
      <nav class="navbar navbar-expand-lg navbar-transparent bg-primary navbar-absolute">
        <div class="container">
          <div class="navbar-translate">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#example-navbar-primary"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-bar bar1" />
              <span class="navbar-toggler-bar bar2" />
              <span class="navbar-toggler-bar bar3" />
            </button>
            <a
              class="navbar-brand"
              href="#pablo"
            >Creative Tim</a>
          </div>
          <div
            id="example-navbar-primary2"
            class="collapse navbar-collapse"
          >
            <ul class="navbar-nav mx-auto">
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  About Us
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <ul class="nav navbar-nav">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://twitter.com/CreativeTim"
                >
                  <i class="fab fa-twitter" />
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.facebook.com/CreativeTim"
                >
                  <i class="fab fa-facebook-square" />
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.instagram.com/CreativeTimOfficial"
                >
                  <i class="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="page-header header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/bg16.jpg')"
        />
        <div class="content-center">
          <div class="container">
            <div class="row">
              <div class="col-md-7 ml-auto text-right">
                <h1 class="title">
                  History of surfing
                </h1>
                <h4 class="description">
                  The riding of waves has likely existed since humans began
                  swimming in the ocean. In this sense, bodysurfing is the oldest type of
                  wave-catching. Standing up on what is now called a surfboard is a relatively recent
                  innovation developed by the Polynesians.
                </h4>
                <br>
                <div class="buttons">
                  <a
                    href="#pablo"
                    class="btn btn-icon btn-link btn-neutral btn-lg"
                  >
                    <i class="fab fa-twitter" />
                  </a>
                  <a
                    href="#pablo"
                    class="btn btn-icon btn-link btn-neutral btn-lg"
                  >
                    <i class="fab fa-facebook-square" />
                  </a>
                  <a
                    href="#pablo"
                    class="btn btn-icon btn-link btn-neutral btn-lg"
                  >
                    <i class="fab fa-get-pocket" />
                  </a>
                  <a
                    href="#pablo"
                    class="btn btn-info btn-lg mr-3"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END HEADER 1      *********      -->
    <!--     *********     HEADER 2      *********      -->
    <div class="header-2">
      <nav class="navbar navbar-expand-lg navbar-transparent bg-primary navbar-absolute">
        <div class="container">
          <div class="navbar-translate">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#example-navbar-primary"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-bar bar1" />
              <span class="navbar-toggler-bar bar2" />
              <span class="navbar-toggler-bar bar3" />
            </button>
            <a
              class="navbar-brand"
              href="#pablo"
            >Creative Tim</a>
          </div>
          <div
            id="example-navbar-primary"
            class="collapse navbar-collapse"
          >
            <ul class="navbar-nav mx-auto">
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  About Us
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  Products
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://twitter.com/CreativeTim"
                >
                  <i class="fab fa-twitter" />
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.facebook.com/CreativeTim"
                >
                  <i class="fab fa-facebook-square" />
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.instagram.com/CreativeTimOfficial"
                >
                  <i class="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="page-header header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/bg14.jpg')"
        />
        <div class="content-center">
          <div class="container">
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto text-center">
                <h1 class="title">
                  You should be here!
                </h1>
                <h5 class="description">
                  5,000 capacity venue, holding some of the latest technology
                  lighting with a 24 colour laser system Amnesia is one of the islands most legendary
                  clubs.
                </h5>
              </div>
              <div class="col-md-10 ml-auto mr-auto">
                <div
                  class="card card-raised card-form-horizontal card-plain"
                  data-background-color
                >
                  <div class="card-body">
                    <form
                      method=""
                      action=""
                    >
                      <div class="row">
                        <div class="col-md-3">
                          <fg-input
                            value=""
                            placeholder="Full Name"
                          />
                        </div>
                        <div class="col-md-3">
                          <fg-input
                            value=""
                            placeholder="Your Email"
                          />
                        </div>
                        <div class="col-md-3">
                          <fg-input
                            value=""
                            placeholder="Password"
                          />
                        </div>
                        <div class="col-md-3">
                          <n-button
                            type="primary"
                            round
                            block
                          >
                            Join Us
                          </n-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END HEADER 2      *********      -->
    <!--     *********     HEADER 3      *********      -->
    <div class="header-3">
      <nav class="navbar navbar-expand-lg navbar-transparent bg-primary navbar-absolute">
        <div class="container">
          <div class="navbar-translate">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#example-navbar-primary"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-bar bar1" />
              <span class="navbar-toggler-bar bar2" />
              <span class="navbar-toggler-bar bar3" />
            </button>
            <a
              class="navbar-brand"
              href="#pablo"
            >Creative Tim</a>
          </div>
          <div
            id="example-navbar-primary"
            class="collapse navbar-collapse"
          >
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  About Us
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pablo"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <el-carousel height="100vh">
        <el-carousel-item>
          <div class="page-header header-filter">
            <div
              class="page-header-image"
              style="background-image: url('img/bg20.jpg')"
            />
            <div class="content-center">
              <div class="container text-left">
                <div class="content-center">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="iframe-container">
                        <iframe
                          height="250"
                          src="https://www.youtube.com/embed/rmfmdKOLzVI?rel=0&amp;controls=0&amp;showinfo=0"
                          frameborder="0"
                          allowfullscreen
                        />
                      </div>
                    </div>
                    <div class="col-md-6 ml-auto mr-auto text-right">
                      <h1 class="title">
                        On the run tour.
                      </h1>
                      <h4 class="description ">
                        On the Run Tour: Beyoncé and Jay Z is a 2014
                        concert special which documents the September 12 and 13, 2014, shows
                        of American singers' Beyoncé and Jay-Z joint co-headlining venture
                        On the Run Tour.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="page-header header-filter">
            <div
              class="page-header-image"
              style="background-image: url('img/bg15.jpg')"
            />
            <div class="content-center">
              <div class="container">
                <div class="content-center">
                  <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                      <h1 class="title">
                        Island of legends.
                      </h1>
                      <h4 class="description ">
                        The islands of Malta and Gozo are brilliant for
                        a family holiday, packed with fun places to visit whatever your
                        children’s ages. The islands’ small size means everywhere is within
                        easy reach.
                      </h4>
                      <br>
                      <h5>Connect with us on:</h5>
                      <div class="buttons">
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-danger btn-round mt-2"
                        >
                          <i class="fab fa-twitter" />
                        </a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-danger btn-round mt-2"
                        >
                          <i class="fab fa-facebook-square" />
                        </a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-danger btn-round mt-2"
                        >
                          <i class="fab fa-google-plus" />
                        </a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-danger btn-round  mt-2"
                        >
                          <i class="fab fa-instagram" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="page-header header-filter">
            <div
              class="page-header-image"
              style="background-image: url('img/bg17.jpg')"
            />
            <div class="content-center">
              <div class="container">
                <div class="content-center">
                  <div class="row">
                    <div class="col-md-6 text-left">
                      <h1 class="title">
                        Arctic Sea ice.
                      </h1>
                      <h4 class="description ">
                        According to the National Oceanic and
                        Atmospheric Administration, Ted Scambos, NSIDC lead scientist, puts
                        the potentially record low maximum sea ice extent this year down to
                        low ice extent in the Pacific and a late drop in ice extent in the
                        Barents Sea.
                      </h4>
                      <br>
                      <div class="buttons">
                        <a
                          href="#pablo"
                          class="btn btn-neutral btn-primary btn-lg mr-1"
                        >
                          <i class="now-ui-icons files_single-copy-04" /> Read More..
                        </a>
                        <a
                          href="#pablo"
                          class="btn btn-primary btn-lg"
                        >
                          <i class="now-ui-icons arrows-1_share-66" /> Subscribe
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--     *********    END HEADER 3      *********      -->
  </div>
</template>
<script>
  import { FormGroupInput, Button } from '@/components';
  import { Carousel, CarouselItem } from 'element-ui'
  export default {
    components:{
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem
    }
  }
</script>
<style>
</style>
