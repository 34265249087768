<template>
    <navbar
            position="fixed"
            :transparent="transparent"
            :color-on-scroll="colorOnScroll"
            menu-classes="ml-auto"
    >
        <template>
            <router-link
                    v-popover:popover1
                    class="navbar-brand"
                    to="/#top"
            >
                IT Link A
            </router-link>
            <el-popover
                    ref="popover1"
                    popper-class="popover"
                    placement="bottom"
                    width="200"
                    trigger="hover"
            >
                <div class="popover-body">
                    Empowering Your Business Through Innovative Software Solutions.
                </div>
            </el-popover>
        </template>
        <template slot="navbar-menu">
            <li class="nav-item">
                <a
                        class="nav-link btn btn-neutral"
                        href="/#strengths"
                >
                    <p>Why to choose us</p>
                </a>
            </li>

            <li class="nav-item">
                <a
                        class="nav-link btn btn-neutral"
                        href="/#technologies"
                >
                    <p>Technologies</p>
                </a>
            </li>

            <li class="nav-item">
                <a
                        class="nav-link btn btn-neutral"
                        href="/#offer"
                >
                    <p>Development we offer </p>
                </a>
            </li>

<!--            <li class="nav-item">
                <a
                        class="nav-link btn btn-twitter"
                        href="https://www.creative-tim.com/product/vue-now-ui-kit-pro"
                        target="_blank"
                >
                    <p>Contact us via messenger</p>
                </a>
            </li>-->

            <li class="nav-item">
                <a
                        class="nav-link btn btn-primary"
                        href="#contacts"
                >
                    <p>Contact us / Order Now</p>
                </a>
            </li>
        </template>
    </navbar>
</template>

<script>
    import { DropDown, Navbar, NavLink } from '@/components';
    import { Popover } from 'element-ui'
    export default {
        name: 'ITLinkANavbar',
        components: {
            DropDown,
            Navbar,
            NavLink,
            [Popover.name]: Popover
        },
        props: {
            transparent: Boolean,
            colorOnScroll: Number,
        }
    }
</script>

<style scoped>

</style>
