<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg32.jpg')"
      />
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">
              About Us
            </h1>
            <h4>Meet the amazing team behind this project and find out more about how we work.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section">
        <div class="container">
          <div class="col-md-12">
            <h2 class="title text-center">
              Why Clients choose us
            </h2>
            <br>
            <div
                    id="blogs-1"
                    class="blogs-1"
            >
              <div class="row">
                <div class="col-md-10 ml-auto mr-auto">
                  <card
                          type="blog"
                          plain
                  >
                    <template slot="raw-content">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="card-image" style="max-width: 300px;">>
                            <img
                                    class="img rounded"
                                    src="img/iconset/icon1.png"
                                    alt=""
                            >
                          </div>
                        </div>
                        <div class="col-md-7">

                          <h2 class="card-title">
                            <a href="#pablo">Lorem ipsum dolor sit amet, consetetur sadipscing elitr</a>
                          </h2>
                          <p class="card-description">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                          </p>
                        </div>
                      </div>
                    </template>
                  </card>
                  <card
                          type="blog"
                          plain
                  >
                    <div class="row">
                      <div class="col-md-7" style="text-align: right">
                        <h2 class="card-title">
                          <a href="#pablo">Lorem ipsum dolor sit amet, consetetur sadipscing elitr</a>
                        </h2>
                        <p class="card-description">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                        </p>
                      </div>
                      <div class="col-md-5">
                        <div class="card-image" style="max-width: 300px;">>
                          <img
                                  class="img rounded"
                                  src="img/iconset/icon2.jpg"
                          >
                        </div>
                      </div>
                    </div>
                  </card>
                  <card
                          type="blog"
                          plain
                  >
                    <template slot="raw-content">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="card-image" style="max-width: 300px;">
                            <img
                                    class="img rounded"
                                    src="img/iconset/icon3.jpg"
                                    alt=""

                            >
                          </div>
                        </div>
                        <div class="col-md-7">

                          <h2 class="card-title">
                            <a href="#pablo">Halo! Lorem ipsum dolor sit amet, consetetur sadipscing elitr</a>
                          </h2>
                          <p class="card-description">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                          </p>
                        </div>
                      </div>
                    </template>
                  </card>
                  <card
                          type="blog"
                          plain
                  >
                    <div class="row">
                      <div class="col-md-7" style="text-align: right">
                        <h2 class="card-title">
                          <a href="#pablo">Lorem ipsum dolor sit amet, consetetur sadipscing elitr</a>
                        </h2>
                        <p class="card-description">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                        </p>
                      </div>
                      <div class="col-md-5">
                        <div class="card-image" style="max-width: 300px;">>
                          <img
                                  class="img rounded"
                                  src="img/iconset/icon4.jpg"
                          >
                        </div>
                      </div>
                    </div>
                  </card>
                  <card
                          type="blog"
                          plain
                  >
                    <template slot="raw-content">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="card-image" style="max-width: 300px;">>
                            <img
                                    class="img rounded"
                                    src="img/iconset/icon5.jpg"
                                    alt=""
                            >
                          </div>
                        </div>
                        <div class="col-md-7">

                          <h2 class="card-title">
                            <a href="#pablo">Lorem ipsum dolor sit amet, consetetur sadipscing elitr</a>
                          </h2>
                          <p class="card-description">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                          </p>
                        </div>
                      </div>
                    </template>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-description text-center">
        <div class="features-3">
          <div class="container">
            <div class="row">
              <div class="col-md-8 mr-auto ml-auto">
                <h2 class="title">
                  Simpler. Smarter. Faster.
                </h2>
                <h4 class="description">
                  The CloudCheckr Cloud Management Platform (CMP) provides
                  full visibility and control to reduce costs, improve cybersecurity posture, and
                  automate critical tasks.
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div class="info info-hover">
                  <div class="icon icon-success icon-circle">
                    <i class="now-ui-icons objects_globe" />
                  </div>
                  <h4 class="info-title">
                    Asset Management
                  </h4>
                  <p class="description">
                    Cross-account dashboards provide sophisticated reporting
                    for enterprise.
                  </p>
                </div>
              </div>
              <div class="col-md-5">
                <div class="info info-hover">
                  <div class="icon icon-info icon-circle">
                    <img src="img/iconset/icon1.jpg">
                  </div>
                  <h4 class="info-title">
                    Resource Utilization
                  </h4>
                  <p class="description">
                    CloudCheckr provides summary and detailed usage
                    statistics for resources.
                  </p>
                </div>
              </div>
<!--              <div class="col-md-4">
                <div class="info info-hover">
                  <div class="icon icon-primary icon-circle">
                    <i class="now-ui-icons tech_watch-time" />
                  </div>
                  <h4 class="info-title">
                    Self-Healing
                  </h4>
                  <p class="description">
                    CloudCheckr enables users to save money, time, and
                    effort.
                  </p>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="separator-line separator-primary" />
      <div class="projects-5">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">
                We have many advantages
              </h2>
              <h4 class="description">
                This is the paragraph where you can write more details about
                your projects. Keep you user engaged by providing meaningful information.
              </h4>
              <div class="section-space" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 ml-auto">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('img/iconset/icon1.jpg')"
              >
                <h2 class="card-title">
                  Social Analytics
                </h2>
                <p class="card-description">
                  Insight to help you create, connect, and convert. Understand Your Audience's
                  Interests, Influence, Interactions, and Intent. Discover emerging topics and
                  influencers to reach new audiences.
                </p>
                <badge type="neutral">
                  Analytics
                </badge>
              </card>
            </div>
            <div class="col-md-5 mr-auto">
              <info-section
                type="danger"
                icon="now-ui-icons ui-2_chat-round"
                title="Listen to Social Conversations"
                description="Gain access to the demographics, psychographics, and location of unique people who talk about your brand."
              />

              <info-section
                type="danger"
                icon="now-ui-icons business_chart-pie-36"
                title="Performance Analyze"
                description="Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich insights from easy-to-use reports."
              />

              <info-section
                type="danger"
                icon="now-ui-icons design-2_ruler-pencil"
                title="Social Conversions"
                description="Track actions taken on your website that originated from social, and understand the impact on your bottom line."
              />
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-5 ml-auto mt-5">
              <info-section
                type="warning"
                icon="now-ui-icons users_single-02"
                title="Work With Any Team"
                description="Whether it’s for work or even the next family vacation, Trello helps your team."
              />

              <info-section
                type="warning"
                icon="now-ui-icons business_chart-bar-32"
                title="A Productivity Platform"
                description="Integrate the apps your team already uses directly into your workflow."
              />

              <info-section
                type="warning"
                icon="now-ui-icons arrows-1_refresh-69"
                title="Always In Sync"
                description="No matter where you are, Trello stays in sync across all of your devices."
              />
            </div>
            <div class="col-md-5 mr-auto">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('img/iconset/icon2.jpg');"
              >
                <h2 class="card-title">
                  Trello lets you work.
                </h2>
                <p class="card-description text-white">
                  Trello’s boards, lists, and cards enable you to organize and prioritize your
                  projects in a fun, flexible and rewarding way.
                </p>
                <badge type="neutral">
                  Trello
                </badge>
              </card>
            </div>
          </div>
        </div>
      </div>
      <div class="about-team team-4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">
                We are nerd rockstars
              </h2>
              <h4 class="description">
                This is the paragraph where you can write more details about
                your team. Keep you user engaged by providing meaningful information.
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card
                type="profile"
                plain
              >
                <div
                  slot="raw-content"
                  class="row"
                >
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img
                          class="img img-raised rounded"
                          src="img/iconset/icon1..jpg"
                        >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">
                        Ella Evelyn
                      </h4>
                      <h6 class="category">
                        Air Crew Member
                      </h6>
                      <p class="card-description">
                        Think in the morning. Act in the noon. Eat in the evening. Sleep in
                        the night......
                      </p>
                      <div class="card-footer">
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-twitter"
                        ><i
                          class="fab fa-twitter"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-facebook"
                        ><i
                          class="fab fa-facebook-square"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-google"
                        ><i
                          class="fab fa-google"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card
                type="profile"
                plain
              >
                <div
                  slot="raw-content"
                  class="row"
                >
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img
                          class="img img-raised rounded"
                          src="img/iconset/icon2.jpg"
                        >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">
                        Mila Skylar
                      </h4>
                      <h6 class="category">
                        Architect
                      </h6>
                      <p class="card-description">
                        Love cures people - both the ones who give it and the ones who
                        receive it...
                      </p>
                      <div class="card-footer">
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-linkedin"
                        ><i
                          class="fab fa-linkedin"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-facebook"
                        ><i
                          class="fab fa-facebook-square"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-dribbble"
                        ><i
                          class="fab fa-dribbble"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-google"
                        ><i
                          class="fab fa-google"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card
                type="profile"
                plain
              >
                <div
                  slot="raw-content"
                  class="row"
                >
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img
                          class="img img-raised rounded"
                          src="img/james.jpg"
                        >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">
                        Daniel Carter
                      </h4>
                      <h6 class="category">
                        Aviation Inspector
                      </h6>
                      <p class="card-description">
                        Keep your face always toward the sunshine - and shadows will fall
                        behind you...
                      </p>
                      <div class="card-footer">
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-youtube"
                        ><i
                          class="fab fa-youtube"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-twitter"
                        ><i
                          class="fab fa-twitter"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-instagram"
                        ><i
                          class="fab fa-instagram"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
              <card
                type="profile"
                plain
              >
                <div
                  slot="raw-content"
                  class="row"
                >
                  <div class="col-md-5">
                    <div class="card-image">
                      <a href="#pablo">
                        <img
                          class="img img-raised rounded"
                          src="img/michael.jpg"
                        >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h4 class="card-title">
                        Dylan Wyatt
                      </h4>
                      <h6 class="category">
                        Conservation Scientist
                      </h6>
                      <p class="card-description">
                        There is only one corner of the universe you can be certain of
                        improving, and that's your own self...
                      </p>
                      <div class="card-footer">
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-linkedin"
                        ><i
                          class="fab fa-linkedin"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-facebook"
                        ><i
                          class="fab fa-facebook-square"
                        /></a>
                        <a
                          href="#pablo"
                          class="btn btn-icon btn-neutral btn-google"
                        ><i
                          class="fab fa-google"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
      <div class="about-office">
        <div class="container">
          <div class="row text-center">
            <div class="col-md-8 ml-auto mr-auto">
              <h2 class="title">
                Our office is our second home
              </h2>
              <h4 class="description">
                Here are some pictures from our office. You can see the place
                looks like a palace and is fully equiped with everything you need to get the job
                done.
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/bg27.jpg"
              >
            </div>
            <div class="col-md-4">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/bg24.jpg"
              >
            </div>
            <div class="col-md-4">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/bg28.jpg"
              >
            </div>
            <div class="col-md-6">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/project6.jpg"
              >
            </div>
            <div class="col-md-6">
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/project14.jpg"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="about-contact">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
              <h2 class="text-center title">
                Want to work with us?
              </h2>
              <h4 class="text-center description">
                Divide details about your product or agency work
                into parts. Write a few lines about each one and contact us about any further
                collaboration. We will get back to you in a couple of hours.
              </h4>
              <form class="contact-form">
                <div class="row">
                  <div class="col-md-4">
                    <label>First name</label>
                    <fg-input
                      v-model="form.firstName"
                      addon-left-icon="now-ui-icons users_circle-08"
                      placeholder="First Name..."
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Your email</label>
                    <fg-input
                      v-model="form.email"
                      addon-left-icon="now-ui-icons ui-1_email-85"
                      placeholder="Email Here..."
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Speciality</label>
                    <fg-input>
                      <el-select
                        v-model="form.speciality"
                        class="select-default"
                      >
                        <el-option
                          class="select-default"
                          value="1"
                          label="I'm a Designer"
                        />
                        <el-option
                          class="select-default"
                          value="2"
                          label="I'm a Developer"
                        />
                        <el-option
                          class="select-default"
                          value="3"
                          label="I'm a Hero"
                        />
                      </el-select>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto text-center">
                    <button class="btn btn-primary btn-round mt-4 btn-lg">
                      Let's talk
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, Badge, InfoSection, FormGroupInput, Checkbox } from '@/components';
  import { Select, Option } from 'element-ui'

  export default {
    name: 'AboutUs',
    bodyClass: 'about-us',
    components: {
      Card,
      InfoSection,
      Badge,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          speciality: ''
        }
      }
    }
  }
</script>
<style>
</style>
