<template>
  <div
    id="projects"
    class="cd-section"
  >
    <!--     *********    PROJECTS 1     *********      -->
    <div class="projects-1">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Some of Our Awesome Products - 1
            </h2>
            <tabs
              type="danger"
              centered
              pills
            >
              <tab-pane label="All" />
              <tab-pane label="History" />
              <tab-pane label="Satire" />
              <tab-pane label="Fiction" />
            </tabs>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 px-0">
            <card
              type="background"
              class="card-background-product card-no-shadow"
              style="background-image: url('img/project1.jpg')"
            >
              <h6 class="category">
                History
              </h6>
              <h3 class="card-title">
                The City Lost & Found
              </h3>
              <p class="card-description">
                Developed on the occasion of an exhibition of the same name, The City Lost & Found:
                Capturing New York...
              </p>
              <n-button
                type="danger"
                round
              >
                <i class="now-ui-icons ui-1_send" /> View Book
              </n-button>
            </card>
          </div>
          <div class="col-md-6 px-0">
            <card
              type="background"
              raised
              class="card-background-product card-no-shadow"
              style="background-image: url('img/project18.jpg')"
            >
              <h6 class="category">
                Satire
              </h6>
              <h3 class="card-title">
                A Confederacy of Dunces
              </h3>
              <p class="card-description">
                Satires, in the most basic definition, are works making fun of some sort of person or
                institution...
              </p>
              <n-button
                type="neutral"
                round
              >
                <i class="now-ui-icons ui-1_send" /> View more
              </n-button>
            </card>
          </div>
          <div class="col-md-6 px-0">
            <card
              type="background"
              class="card-background-product card-no-shadow"
              style="background-image: url('img/project20.jpg')"
            >
              <h6 class="category">
                Fiction
              </h6>
              <h3 class="card-title">
                The Sun Also Rises
              </h3>
              <p class="card-description">
                The most commonly read works are works of fiction. Fiction books are ones that have been
                made up...
              </p>
              <n-button
                type="neutral"
                round
              >
                <i class="now-ui-icons ui-1_send" /> Read Now
              </n-button>
            </card>
          </div>
          <div class="col-md-6 px-0">
            <card
              type="background"
              class="card-background-product card-no-shadow"
              style="background-image: url('img/project19.jpg')"
            >
              <h6 class="category">
                Basic Civitas
              </h6>
              <h3 class="card-title">
                The Right Mistake
              </h3>
              <p class="card-description">
                Living in South Central L.A., Socrates Fortlow is a sixty-year-old ex-convict, still
                strong enough to kill men with...
              </p>
              <n-button
                type="danger"
                round
              >
                <i class="now-ui-icons ui-1_send" /> Read Now
              </n-button>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END PROJECTS 1      *********      -->
    <!--     *********    PROJECTS 2     *********      -->
    <div class="projects-2">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h6 class="text-muted">
              Our work
            </h6>
            <h2 class="title">
              Some of Our Awesome Products - 2
            </h2>
            <h5 class="description">
              This is the paragraph where you can write more details about your
              projects. Keep you user engaged by providing meaningful information.
            </h5>
            <div class="section-space" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <card plain>
              <a
                slot="image"
                href="https:///www.creative-tim.com/product/now-ui-kit"
                target="_blank"
              >
                <img
                  class="img-raised rounded"
                  src="https:///s3.amazonaws.com/creativetim_bucket/products/38/original/opt_mk_thumbnail.jpg?1458052306"
                >
              </a>


              <a
                href="https:///www.creative-tim.com/product/now-ui-kit"
                target="_blank"
              >
                <h4 class="card-title">Now UI Kit Free</h4>
              </a>
              <h6 class="category text-primary">
                Free UI Kit
              </h6>
              <p class="card-description">
                Now UI Kit is a Free Bootstrap UI Kit with a fresh, new design inspired by Google's
                material design. It's a great pleasure to introduce to you the material concepts in
                an easy to use and beautiful set of components.
              </p>
            </card>
          </div>
          <div class="col-md-4">
            <card plain>
              <a
                slot="image"
                href="https:///www.creative-tim.com/product/light-bootstrap-dashboard-pro"
                target="_blank"
              >
                <img
                  class="img-raised rounded"
                  src="https:///s3.amazonaws.com/creativetim_bucket/products/34/original/opt_lbd_pro_thumbnail.jpg?1449352503"
                >
              </a>


              <a
                href="https:///www.creative-tim.com/product/light-bootstrap-dashboard-pro"
                target="_blank"
              >
                <h4 class="card-title">Light Bootstrap Dashboard</h4>
              </a>
              <h6 class="category text-primary">
                Premium Template
              </h6>
              <p class="card-description">
                Light Bootstrap Dashboard PRO is a Bootstrap Admin Theme designed to look simple and
                beautiful. Forget about boring dashboards and grab yourself a copy to kickstart new
                project!
              </p>
            </card>
          </div>
          <div class="col-md-4">
            <card plain>
              <a
                slot="image"
                href="https:///www.creative-tim.com/product/get-shit-done-pro"
                target="_blank"
              >
                <img
                  class="img-raised rounded"
                  src="https:///s3.amazonaws.com/creativetim_bucket/products/26/original/opt_gsdk_new_thumbnail.jpg"
                >
              </a>


              <a
                href="https:///www.creative-tim.com/product/get-shit-done-pro"
                target="_blank"
              >
                <h4 class="card-title">Get Shit Done Kit PRO</h4>
              </a>
              <h6 class="category text-primary">
                Premium UI Kit
              </h6>
              <p class="card-description">
                Get Shit Done Kit Pro it's a Bootstrap Kit that comes with a huge number of
                customisable components. They are pixel perfect, light and easy to use and combine
                with other elements.
              </p>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END PROJECTS 2      *********      -->
    <!--     *********    PROJECTS 3     *********      -->
    <div
      class="projects-3"
      data-background-color="black"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h6 class="category text-muted">
              Our work
            </h6>
            <h2 class="title">
              Some of Our Awesome Projects - 3
            </h2>
          </div>
        </div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/project4.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "Amber Interiors is available to assist in all
                  aspects of a project from start to finish including consulting with Architects and
                  Builders"
                </p>
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/project21.jpg')"
              />
              <h6 class="category text-primary">
                Design
              </h6>
              <h4 class="card-title">
                Coco Kelley
              </h4>
              <p>
                Amber Lewis’s interior design blog takes you inside the creative workings of her Los
                Angeles–based studio. Keep an eye on this website for an industry insider’s point of
                view.
              </p>
            </div>
          </div>
        </div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <!-- First image on the left side -->
              <div
                class="image-container image-left"
                style="background-image: url('img/project24.jpg')"
              >
                <p class="blockquote blockquote-primary">
                  "We provide a simple, approachable way to
                  decorate your desired space with ease. Each e-décor is completely individualized"
                </p>
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/project23.jpg')"
              />
              <h6 class="category text-primary">
                E-Decor
              </h6>
              <h4 class="card-title">
                Red Trad
              </h4>
              <p>
                We designed this new build family home located in Santa Monica with a mission to make
                the large size of the property, feel cozy, collected, and unique.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END PROJECTS 3      *********      -->
    <!--     *********    PROJECTS 4     *********      -->
    <div
      class="projects-4"
      data-background-color="gray"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mr-auto ml-auto text-center">
            <h6 class="category text-muted">
              Our work
            </h6>
            <h2 class="title">
              Some of Our Awesome Projects - 4
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 px-0">
            <card
              type="background"
              class="card-fashion"
              style="background-image: url('img/project14.jpg')"
            >
              <div class="card-body">
                <div class="card-title text-left">
                  <h2>
                    <a href="#pablo">
                      The “crazy idea that could lead to a big future commission” project.
                    </a>
                  </h2>
                </div>
                <div class="card-footer text-left">
                  <div class="stats">
                    <span>
                      <i class="now-ui-icons objects_globe" />The “Crazy idea” project
                    </span>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-6 px-0">
            <div class="card-container">
              <div class="card card-fashion">
                <div class="card-title">
                  <a href="#pablo">
                    <h4>
                      <a href="#pablo">
                        The next time you’re on vacation, take advantage of all the work you can
                        get done...
                      </a>
                    </h4>
                  </a>
                </div>
                <div class="card-body">
                  <div class="card-footer">
                    <div class="stats">
                      <span>
                        <i class="now-ui-icons education_paper" /> The “I’m on vacation” project
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <card
                type="background"
                class="card-fashion"
                style="background-image: url('img/project15.jpg')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="card-container">
              <card
                type="background"
                class="card-fashion"
                style="background-image: url('img/project17.jpg')"
              />
              <div class="card card-fashion arrow-left">
                <div class="card-title">
                  <h4>
                    <a href="#pablo">
                      The elements of Agile Project Management have been around for decades...
                    </a>
                  </h4>
                </div>
                <div class="card-body">
                  <div class="card-footer">
                    <div class="stats">
                      <span>
                        <i class="now-ui-icons design_app" />The "Agile Project" project
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 px-0">
            <card
              type="background"
              class="card-fashion"
              style="background-image: url('img/project16.jpg')"
            >
              <div class="card-title text-left">
                <h2>
                  <a href="#pablo">Performing a Project Premortem</a>
                </h2>
              </div>
              <div class="card-footer text-left">
                <div class="stats">
                  <span>
                    <i class="now-ui-icons media-2_sound-wave" />The "Premortem" project
                  </span>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END PROJECTS 4      *********      -->
    <!--     *********    PROJECTS 5     *********      -->
    <div class="projects-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Some of Our Awesome Projects - 5
            </h2>
            <h4 class="description">
              This is the paragraph where you can write more details about your
              projects. Keep you user engaged by providing meaningful information.
            </h4>
            <div class="section-space" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 ml-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/project8.jpg')"
            >
              <h2 class="card-title">
                Social Analytics
              </h2>
              <p class="card-description">
                Insight to help you create, connect, and convert. Understand Your Audience's
                Interests, Influence, Interactions, and Intent. Discover emerging topics and
                influencers to reach new audiences.
              </p>
              <badge type="neutral">
                Analytics
              </badge>
            </card>
          </div>
          <div class="col-md-5 mr-auto">
            <info-section
              type="danger"
              icon="now-ui-icons ui-2_chat-round"
              title="Listen to Social Conversations"
              description="Gain access to the demographics, psychographics, and location of unique people who talk about your brand."
            />

            <info-section
              type="danger"
              icon="now-ui-icons business_chart-pie-36"
              title="Performance Analyze"
              description="Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich insights from easy-to-use reports."
            />

            <info-section
              type="danger"
              icon="now-ui-icons design-2_ruler-pencil"
              title="Social Conversions"
              description="Track actions taken on your website that originated from social, and understand the impact on your bottom line."
            />
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-5 ml-auto mt-5">
            <info-section
              type="warning"
              icon="now-ui-icons users_single-02"
              title="Work With Any Team"
              description="Whether it’s for work or even the next family vacation, Trello helps your team."
            />

            <info-section
              type="warning"
              icon="now-ui-icons business_chart-bar-32"
              title="A Productivity Platform"
              description="Integrate the apps your team already uses directly into your workflow."
            />

            <info-section
              type="warning"
              icon="now-ui-icons arrows-1_refresh-69"
              title="Always In Sync"
              description="No matter where you are, Trello stays in sync across all of your devices."
            />
          </div>
          <div class="col-md-5 mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/project9.jpg')"
            >
              <h2 class="card-title">
                Trello lets you work.
              </h2>
              <p class="card-description ">
                Trello’s boards, lists, and cards enable you to organize and prioritize your
                projects in a fun, flexible and rewarding way.
              </p>
              <badge type="neutral">
                Trello
              </badge>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END PROJECTS 5      *********      -->
  </div>
</template>
<script>
  import { Card, InfoSection,  Badge, Button, Tabs, TabPane } from '@/components';

  export default {
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      InfoSection,
      [Button.name]: Button
    }
  }
</script>
<style>
</style>
