<template>
  <div
    class="section section-footers"
    data-background-color="gray"
  >
    <div class="container">
      <div class="title">
        <h3>Footer Areas</h3>
      </div>
    </div>
    <div id="footer-areas">
      <!--     *********    SIMPLE FOOTER WITH SOCIAL AND BRAND     *********      -->
      <footer class="footer footer-white">
        <div class="container">
          <a
            class="footer-brand"
            href="https:///www.creative-tim.com"
          >Now Ui Kit PRO</a>
          <ul class="pull-center">
            <li>
              <a
                href="#pablo"
                class="nav-link"
              >
                Creative Tim
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                class="nav-link"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                class="nav-link"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                class="nav-link"
              >
                Licenses
              </a>
            </li>
          </ul>
          <ul class="social-buttons pull-right">
            <li>
              <a
                href="https://twitter.com/CreativeTim"
                target="_blank"
                class="btn btn-icon btn-neutral btn-twitter"
              >
                <i class="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/CreativeTim"
                target="_blank"
                class="btn btn-icon btn-neutral btn-dribbble"
              >
                <i class="fab fa-dribbble" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/CreativeTimOfficial"
                target="_blank"
                class="btn btn-icon btn-neutral btn-google"
              >
                <i class="fab fa-google-plus" />
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <!--     *********   END SIMPLE FOOTER WITH SOCIAL AND BRAND     *********      -->
      <br>
      <br>
      <!--     *********    SIMPLE FOOTER     *********      -->
      <footer class="footer">
        <div class="container">
          <nav>
            <ul>
              <li>
                <a
                  href="https://www.creative-tim.com"
                  class="nav-link"
                >
                  Creative Tim
                </a>
              </li>
              <li>
                <a
                  href="https:///presentation.creative-tim.com"
                  class="nav-link"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https:///blog.creative-tim.com"
                  class="nav-link"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="https://www.creative-tim.com/license"
                  target="_blank"
                  class="nav-link"
                >
                  License
                </a>
              </li>
            </ul>
          </nav>
          <div class="copyright">
            &copy;
            {{ year }}
            , Designed by
            <a
              href="https:///www.invisionapp.com"
              target="_blank"
              class="copyright-link"
            >Invision</a>. Coded
            by
            <a
              href="https://www.creative-tim.com"
              target="_blank"
              class="copyright-link"
            >Creative Tim</a>.
          </div>
        </div>
      </footer>
      <!--     *********   END SIMPLE FOOTER     *********      -->
      <br>
      <br>
      <!--     *********    BLACK SIMPLE FOOTER WITH SOCIAL AND BRAND     *********      -->
      <footer
        class="footer"
        data-background-color="black"
      >
        <div class="container">
          <a
            class="footer-brand"
            href="#pablo"
          >Now Ui Kit PRO</a>
          <ul class="pull-center">
            <li>
              <a href="#pablo">
                Blog
              </a>
            </li>
            <li>
              <a href="#pablo">
                Presentation
              </a>
            </li>
            <li>
              <a href="#pablo">
                Discover
              </a>
            </li>
            <li>
              <a href="#pablo">
                Payment
              </a>
            </li>
            <li>
              <a href="#pablo">
                Contact Us
              </a>
            </li>
          </ul>
          <ul class="social-buttons pull-right">
            <li>
              <a
                href="https://twitter.com/CreativeTim"
                target="_blank"
                class="btn btn-icon btn-link btn-neutral"
              >
                <i class="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/CreativeTim"
                target="_blank"
                class="btn btn-icon btn-neutral btn-link"
              >
                <i class="fab fa-facebook-square" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/CreativeTimOfficial"
                target="_blank"
                class="btn btn-icon btn-neutral btn-link"
              >
                <i class="fab fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <!--     *********   END BLACK SIMPLE FOOTER WITH SOCIAL AND BRAND     *********      -->
      <br>
      <br>
      <!--     *********    BIG FOOTER     *********      -->
      <footer class="footer footer-big footer-white">
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-md-4">
                <h5>About Us</h5>
                <p>
                  Creative Tim is a startup that creates design tools that make the web development
                  process faster and easier.
                </p>
                <p>
                  We love the web and care deeply for how users interact with a digital product. We
                  power businesses and individuals to create better looking web projects around the
                  world.
                </p>
              </div>
              <div class="col-md-4">
                <h5>Social Feed</h5>
                <div class="social-feed">
                  <div class="feed-line">
                    <i class="fab fa-twitter" />
                    <p>How to handle ethical disagreements with your clients.</p>
                  </div>
                  <div class="feed-line">
                    <i class="fab fa-twitter" />
                    <p>The tangible benefits of designing at 1x pixel density.</p>
                  </div>
                  <div class="feed-line">
                    <i class="fab fa-facebook-square" />
                    <p>A collection of 25 stunning sites that you can use for inspiration.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <h5>Instagram Feed</h5>
                <div class="gallery-feed">
                  <img
                    v-lazy="'img/olivia.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                  <img
                    v-lazy="'img/james.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                  <img
                    v-lazy="'img/michael.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                  <img
                    v-lazy="'img/emily.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                  <img
                    v-lazy="'img/marie.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                  <img
                    v-lazy="'img/usher.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                  <img
                    v-lazy="'img/mike.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                  <img
                    v-lazy="'img/julie.jpg'"
                    class="img img-raised rounded"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
          <hr>
          <ul class="pull-left">
            <li>
              <a href="#pablo">
                Blog
              </a>
            </li>
            <li>
              <a href="#pablo">
                Presentation
              </a>
            </li>
            <li>
              <a href="#pablo">
                Discover
              </a>
            </li>
            <li>
              <a href="#pablo">
                Payment
              </a>
            </li>
            <li>
              <a href="#pablo">
                Contact Us
              </a>
            </li>
          </ul>
          <div class="copyright pull-right">
            Copyright &copy;
            {{ year }}
            Creative Tim All Rights Reserved.
          </div>
        </div>
      </footer>
      <!--     *********   END BIG FOOTER     *********      -->
      <br>
      <br>
      <!--     *********    BIG BlACK FOOTER     *********      -->
      <footer
        class="footer footer-big"
        data-background-color="black"
      >
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-md-2">
                <h5>About Us</h5>
                <ul class="links-vertical">
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Presentation
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-2">
                <h5>Market</h5>
                <ul class="links-vertical">
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Sales FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      How to Register
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Sell Goods
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Receive Payment
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Transactions Issues
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="text-muted"
                    >
                      Affiliates Program
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <h5>Social Feed</h5>
                <div class="social-feed">
                  <div class="feed-line">
                    <i class="fab fa-twitter" />
                    <p>How to handle ethical disagreements with your clients.</p>
                  </div>
                  <div class="feed-line">
                    <i class="fab fa-twitter" />
                    <p>The tangible benefits of designing at 1x pixel density.</p>
                  </div>
                  <div class="feed-line">
                    <i class="fab fa-facebook-square" />
                    <p>A collection of 25 stunning sites that you can use for inspiration.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <h5>Follow Us</h5>
                <ul class="social-buttons">
                  <li>
                    <a
                      href="#pablo"
                      class="btn btn-icon btn-neutral btn-twitter btn-round"
                    >
                      <i class="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="btn btn-icon btn-neutral btn-facebook btn-round"
                    >
                      <i class="fab fa-facebook-square" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="btn btn-icon btn-neutral btn-dribbble btn-round"
                    >
                      <i class="fab fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="btn btn-icon btn-neutral btn-google btn-round"
                    >
                      <i class="fab fa-google-plus" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pablo"
                      class="btn btn-icon btn-neutral btn-instagram btn-round"
                    >
                      <i class="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
                <h5>
                  <small>Numbers Don't Lie</small>
                </h5>
                <h5>
                  14.521
                  <small class="text-muted">Freelancers</small>
                </h5>
                <h5>
                  1.423.183
                  <small class="text-muted">Transactions</small>
                </h5>
              </div>
            </div>
          </div>
          <hr>
          <div class="copyright">
            Copyright &copy;
            {{ year }}
            Creative Tim All Rights Reserved.
          </div>
        </div>
      </footer>
      <!--     *********   END BIG WHITE FOOTER     *********      -->
      <br>
      <br>
      <!--     *********    BIG WHITE FOOTER V2     *********      -->
      <footer class="footer footer-white footer-big">
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-md-3">
                <a href="#pablo">
                  <h5>Now Ui Kit PRO</h5>
                </a>
                <p>
                  Probably the best UI Kit in the world! We know you've been waiting for it, so don't
                  be shy!
                </p>
              </div>
              <div class="col-md-2">
                <h5>About</h5>
                <ul class="links-vertical">
                  <li>
                    <a href="#pablo">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      Presentation
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-2">
                <h5>Market</h5>
                <ul class="links-vertical">
                  <li>
                    <a href="#pablo">
                      Sales FAQ
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      How to Register
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      Sell Goods
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      Receive Payment
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      Transactions Issues
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-2">
                <h5>Legal</h5>
                <ul class="links-vertical">
                  <li>
                    <a href="#pablo">
                      Transactions FAQ
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a href="#pablo">
                      Licenses
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-3">
                <h5>Subscribe to Newsletter</h5>
                <p>
                  Join our newsletter and get news in your inbox every week! We hate spam too, so no
                  worries about this.
                </p>
                <form
                  class="form form-newsletter"
                  method=""
                  action=""
                >
                  <fg-input placeholder="Your Email..." />
                  <n-button
                    round
                    icon
                    type="primary"
                  >
                    <i class="now-ui-icons ui-1_email-85" />
                  </n-button>
                </form>
              </div>
            </div>
          </div>
          <hr>
          <ul class="social-buttons">
            <li>
              <a
                href="#pablo"
                class="btn btn-icon btn-neutral btn-twitter btn-lg"
              >
                <i class="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                class="btn btn-icon btn-neutral btn-facebook btn-lg"
              >
                <i class="fab fa-facebook-square" />
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                class="btn btn-icon btn-neutral btn-dribbble btn-lg"
              >
                <i class="fab fa-dribbble" />
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                class="btn btn-icon btn-neutral btn-google btn-lg"
              >
                <i class="fab fa-google-plus" />
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                class="btn btn-icon btn-neutral btn-youtube btn-lg"
              >
                <i class="fab fa-youtube" />
              </a>
            </li>
          </ul>
          <div class="copyright pull-center">
            Copyright &copy;
            {{ year }}
            Creative Tim All Rights Reserved.
          </div>
        </div>
      </footer>
      <!--     *********   END BIG WHITE FOOTER v2     *********      -->
    </div>
  </div>
</template>
<script>
    import {FormGroupInput, Button} from '@/components';
    export default {
        components: {
          [FormGroupInput.name]: FormGroupInput,
          [Button.name]: Button
        },
        data(){
            return {
                year: new Date().getFullYear()
            }
        }
    }
</script>
<style>
</style>
