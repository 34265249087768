<template>
  <div
    id="features"
    class="cd-section"
  >
    <!--     *********     FEATURES 1      *********      -->
    <div class="features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h2 class="title">
              Full-Funnel Social Analytics
            </h2>
            <h4 class="description">
              Insight to help you create, connect, and convert. Understand Your Audience's Interests, Influence, Interactions, and Intent. Discover emerging topics and influencers to reach new audiences.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-primary">
                <i class="now-ui-icons ui-2_chat-round" />
              </div>
              <h4 class="info-title">
                Social Conversations
              </h4>
              <p class="description">
                Gain access to the demographics, psychographics, and location of unique people.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-success">
                <i class="now-ui-icons business_chart-pie-36" />
              </div>
              <h4 class="info-title">
                Analyze Performance
              </h4>
              <p class="description">
                Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich insights.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-warning">
                <i class="now-ui-icons design-2_ruler-pencil" />
              </div>
              <h4 class="info-title">
                Measure Conversions
              </h4>
              <p class="description">
                Track actions taken on your website, understand the impact on your bottom line.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 1      *********      -->
    <!--     *********     FEATURES 2      *********      -->
    <div
      class="features-2 section-image"
      style="background-image: url('img/bg22.jpg')"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mr-auto ml-auto">
            <h2 class="title">
              Trello lets you work more.
            </h2>
            <h4 class="description">
              Trello’s boards, lists, and cards enable you to organize and prioritize your projects in a fun, flexible and rewarding way.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info">
              <div class="icon icon-primary icon-circle">
                <i class="now-ui-icons users_single-02" />
              </div>
              <h4 class="info-title">
                Work With Any Team
              </h4>
              <p class="description">
                Whether it’s for work or even the next family vacation, Trello helps your team.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div class="icon icon-danger icon-circle">
                <i class="now-ui-icons business_chart-bar-32" />
              </div>
              <h4 class="info-title">
                A Productivity Platform
              </h4>
              <p class="description">
                Integrate the apps your team already uses directly into your workflow.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div class="icon icon-warning icon-circle">
                <i class="now-ui-icons arrows-1_refresh-69" />
              </div>
              <h4 class="info-title">
                Always In Sync
              </h4>
              <p class="description">
                No matter where you are, Trello stays in sync across all of your devices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 2      *********      -->
    <!--     *********     FEATURES 3      *********      -->
    <div class="features-3">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h2 class="title">
              Simpler. Smarter. Faster.
            </h2>
            <h4 class="description">
              The CloudCheckr Cloud Management Platform (CMP) provides full visibility and control to reduce costs, improve cybersecurity posture, and automate critical tasks.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-success icon-circle">
                <i class="now-ui-icons objects_globe" />
              </div>
              <h4 class="info-title">
                Asset Management
              </h4>
              <p class="description">
                Cross-account dashboards provide sophisticated reporting for enterprise.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-info icon-circle">
                <i class="now-ui-icons education_atom" />
              </div>
              <h4 class="info-title">
                Resource Utilization
              </h4>
              <p class="description">
                CloudCheckr provides summary and detailed usage statistics for resources.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-primary icon-circle">
                <i class="now-ui-icons tech_watch-time" />
              </div>
              <h4 class="info-title">
                Self-Healing
              </h4>
              <p class="description">
                CloudCheckr enables users to save money, time, and effort.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 3      *********      -->
    <!--     *********     FEATURES 4      *********      -->
    <div class="features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Coming home, a pleasure!
            </h2>
            <h4 class="description">
              Havenly is a convenient, personal and affordable way to redecorate your home room by room. Collaborate with our professional interior designers on our online platform.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <card
              type="background"
              raised
              color="transparent"
              style="background-image: url('img/bg24.jpg')"
            >
              <template slot="info">
                <div class="icon icon-white">
                  <i class="now-ui-icons business_bulb-63" />
                </div>
                <div class="description">
                  <h4 class="info-title">
                    Collaborate on ideas
                  </h4>
                  <p>Your designer will come back to you with an initial set of ideas.</p>
                  <a
                    href="#pablo"
                    class="ml-3"
                  >Find more...</a>
                </div>
              </template>
            </card>
          </div>
          <div class="col-md-4">
            <card
              type="background"
              raised
              color="transparent"
              style="background-image: url('img/bg23.jpg')"
            >
              <template slot="info">
                <div class="icon icon-white">
                  <i class="now-ui-icons business_badge" />
                </div>
                <div class="description">
                  <h4 class="info-title">
                    Pick your designer
                  </h4>
                  <p>Havenly interior designers are vetted professionals and real people.</p>
                  <a href="#pablo">Find more...</a>
                </div>
              </template>
            </card>
          </div>
          <div class="col-md-4">
            <card
              type="background"
              raised
              color="transparenr"
              style="background-image: url('img/bg25.jpg')"
            >
              <template slot="info">
                <div class="icon icon-white">
                  <i class="now-ui-icons location_map-big" />
                </div>
                <div class="description">
                  <h4 class="info-title">
                    Visualize your room
                  </h4>
                  <p>Share a floor plan, and we'll create a visualization of your room.</p>
                  <a
                    href="#pablo"
                    class="ml-3"
                  >Find more...</a>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 4      *********      -->
    <!--     *********     FEATURES 5      *********      -->
    <div class="features-5">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <h2 class="title">
              Working is a pleasure
            </h2>
            <div class="info info-horizontal">
              <div class="icon icon-primary icon-circle">
                <i class="now-ui-icons location_world" />
              </div>
              <div class="description">
                <h4 class="info-title">
                  Hundreds of Components
                </h4>
                <p>The moment you use Now UI Kit, you know you’ve never felt anything like it. With a single use lets you do more than ever before. </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary icon-circle">
                <i class="now-ui-icons sport_user-run" />
              </div>
              <div class="description">
                <h4 class="info-title">
                  Easy to Use
                </h4>
                <p>Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature.</p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary icon-circle">
                <i class="now-ui-icons ui-2_time-alarm" />
              </div>
              <div class="description">
                <h4 class="info-title">
                  Fast Prototyping
                </h4>
                <p>Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature.</p>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="tablet-container">
              <img v-lazy="'img/ipad2-inverted.png'">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 5      *********      -->
    <!--     *********     FEATURES 6      *********      -->
    <div class="features-6">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Working is a pleasure
            </h2>
            <h4 class="description">
              This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info info-horizontal">
              <div class="icon icon-info">
                <i class="now-ui-icons design-2_html5" />
              </div>
              <div class="description">
                <h5 class="info-title">
                  For Developers
                </h5>
                <p>The moment you use Now UI Kit, you know you’ve never felt anything like it. With a single use, this powerfull UI Kit lets you do more than ever before. </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-danger">
                <i class="now-ui-icons design_palette" />
              </div>
              <div class="description">
                <h4 class="info-title">
                  For Designers
                </h4>
                <p>Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="phone-container">
              <img v-lazy="'img/iphone2.png'">
            </div>
          </div>
          <div class="col-md-4">
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons design-2_ruler-pencil" />
              </div>
              <div class="description">
                <h5 class="info-title">
                  Bootstrap Grid
                </h5>
                <p>Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough.</p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-success">
                <i class="now-ui-icons files_single-copy-04" />
              </div>
              <div class="description">
                <h4 class="info-title">
                  Example Pages Included
                </h4>
                <p>Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 6      *********      -->
    <!--     *********     FEATURES 7      *********      -->
    <div
      class="features-7 section-image"
      style="background-image: url('img/bg11.jpg')"
    >
      <div class="col-md-8 mr-auto ml-auto text-center">
        <h2 class="title">
          Working is a pleasure
        </h2>
        <h4 class="description">
          This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information.
        </h4>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="col-sm-12">
              <div class="info info-horizontal">
                <div class="icon">
                  <i class="now-ui-icons design-2_html5" />
                </div>
                <div class="description">
                  <h5 class="info-title">
                    For Developers
                  </h5>
                  <p class="description">
                    The moment you use Now UI Kit, you know you’ve never felt anything like it. With a single use, this powerfull UI Kit lets you do more than ever before.
                  </p>
                </div>
              </div>
              <div class="info info-horizontal">
                <div class="icon">
                  <i class="now-ui-icons design_palette" />
                </div>
                <div class="description">
                  <h5 class="info-title">
                    For Designers
                  </h5>
                  <p class="description">
                    The moment you use Now UI Kit, you know you’ve never felt anything like it. With a single use, this powerfull UI Kit lets you do more than ever before.
                  </p>
                </div>
              </div>
              <div class="info info-horizontal">
                <div class="icon">
                  <i class="now-ui-icons design-2_ruler-pencil" />
                </div>
                <div class="description">
                  <h5 class="info-title">
                    For Artists
                  </h5>
                  <p class="description">
                    The moment you use Now UI Kit, you know you’ve never felt anything like it. With a single use, this powerfull UI Kit lets you do more than ever before.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="image-container">
              <img
                v-lazy="'img/ipad3.png'"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 7      *********      -->
    <!--     *********     FEATURES 8      *********      -->
    <div
      class="features-8 section-image"
      style="background-image: url('img/bg30.jpg')"
    >
      <div class="col-md-8 ml-auto mr-auto text-center">
        <h2 class="title">
          Working is a pleasure
        </h2>
        <h4 class="description">
          Woodpecker.co is a follow-up automation SaaS founded in 2015. We’re an international team of 17, speaking 4 languages. Our mission is to enable all B2B companies to connect with their Ideal Customers.
        </h4>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <card>
              <img
                slot="image"
                src="img/bg28.jpg"
                class="rounded"
                alt=""
              >
              <div class="info text-center">
                <div class="icon">
                  <i class="now-ui-icons ui-1_email-85" />
                </div>
                <h4 class="info-title">
                  Reply detection
                </h4>
                <p class="description">
                  If you get a reply, further follow-ups are automatically stopped.
                </p>
              </div>
            </card>
          </div>
          <div class="col-md-3">
            <card>
              <img
                slot="image"
                src="img/bg26.jpg"
                class="rounded"
                alt=""
              >
              <div class="info text-center">
                <div class="icon">
                  <i class="now-ui-icons ui-1_calendar-60" />
                </div>
                <h4 class="info-title">
                  Follow-up
                </h4>
                <p class="description">
                  Just set a number of days that you want send a follow-up.
                </p>
              </div>
            </card>
          </div>
          <div class="col-md-3">
            <card>
              <img
                slot="image"
                src="img/bg27.jpg"
                class="rounded"
                alt=""
              >
              <div class="info text-center">
                <div class="icon">
                  <i class="now-ui-icons ui-1_send" />
                </div>
                <h4 class="info-title">
                  Cold email
                </h4>
                <p class="description">
                  Unlimited number of prospects daily with personalized emails.
                </p>
              </div>
            </card>
          </div>
          <div class="col-md-3">
            <card>
              <img
                slot="image"
                src="img/bg29.jpg"
                class="rounded"
                alt=""
              >
              <div class="info text-center">
                <div class="icon">
                  <i class="now-ui-icons ui-2_like" />
                </div>
                <h4 class="info-title">
                  Teamwork
                </h4>
                <p class="description">
                  You and your teammates won’t address the same person twice.
                </p>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END FEATURES 8      *********      -->
  </div>
</template>
<script>
  import { Card } from '@/components';
  export default {
    components: {
      Card
    }
  }
</script>
<style>
</style>
